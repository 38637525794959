exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-[slug]-p-tsx": () => import("./../../../src/pages/[slug]/p.tsx" /* webpackChunkName: "component---src-pages-[slug]-p-tsx" */),
  "component---src-pages-aceitar-termos-promocao-tsx": () => import("./../../../src/pages/aceitar-termos-promocao.tsx" /* webpackChunkName: "component---src-pages-aceitar-termos-promocao-tsx" */),
  "component---src-pages-atendimento-tsx": () => import("./../../../src/pages/atendimento.tsx" /* webpackChunkName: "component---src-pages-atendimento-tsx" */),
  "component---src-pages-baixe-nosso-app-tsx": () => import("./../../../src/pages/baixe-nosso-app.tsx" /* webpackChunkName: "component---src-pages-baixe-nosso-app-tsx" */),
  "component---src-pages-busca-multipla-tsx": () => import("./../../../src/pages/busca-multipla.tsx" /* webpackChunkName: "component---src-pages-busca-multipla-tsx" */),
  "component---src-pages-campanhas-tsx": () => import("./../../../src/pages/campanhas.tsx" /* webpackChunkName: "component---src-pages-campanhas-tsx" */),
  "component---src-pages-club-informacao-tsx": () => import("./../../../src/pages/club-informacao.tsx" /* webpackChunkName: "component---src-pages-club-informacao-tsx" */),
  "component---src-pages-club-tsx": () => import("./../../../src/pages/club.tsx" /* webpackChunkName: "component---src-pages-club-tsx" */),
  "component---src-pages-clube-registrado-tsx": () => import("./../../../src/pages/clube-registrado.tsx" /* webpackChunkName: "component---src-pages-clube-registrado-tsx" */),
  "component---src-pages-colecao-slug-tsx": () => import("./../../../src/pages/colecao/[...slug].tsx" /* webpackChunkName: "component---src-pages-colecao-slug-tsx" */),
  "component---src-pages-compre-novamente-tsx": () => import("./../../../src/pages/compre-novamente.tsx" /* webpackChunkName: "component---src-pages-compre-novamente-tsx" */),
  "component---src-pages-cupons-de-oferta-tsx": () => import("./../../../src/pages/cupons-de-oferta.tsx" /* webpackChunkName: "component---src-pages-cupons-de-oferta-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institucional-atendimento-tsx": () => import("./../../../src/pages/institucional/atendimento.tsx" /* webpackChunkName: "component---src-pages-institucional-atendimento-tsx" */),
  "component---src-pages-institucional-lojas-tsx": () => import("./../../../src/pages/institucional/lojas.tsx" /* webpackChunkName: "component---src-pages-institucional-lojas-tsx" */),
  "component---src-pages-institucional-politica-cookie-tsx": () => import("./../../../src/pages/institucional/politica-cookie.tsx" /* webpackChunkName: "component---src-pages-institucional-politica-cookie-tsx" */),
  "component---src-pages-institucional-privacidade-tsx": () => import("./../../../src/pages/institucional/privacidade.tsx" /* webpackChunkName: "component---src-pages-institucional-privacidade-tsx" */),
  "component---src-pages-institucional-sobre-tsx": () => import("./../../../src/pages/institucional/sobre.tsx" /* webpackChunkName: "component---src-pages-institucional-sobre-tsx" */),
  "component---src-pages-institucional-termos-tsx": () => import("./../../../src/pages/institucional/termos.tsx" /* webpackChunkName: "component---src-pages-institucional-termos-tsx" */),
  "component---src-pages-institucional-trabalhe-conosco-tsx": () => import("./../../../src/pages/institucional/trabalheConosco.tsx" /* webpackChunkName: "component---src-pages-institucional-trabalhe-conosco-tsx" */),
  "component---src-pages-o-que-te-interessou-tsx": () => import("./../../../src/pages/o-que-te-interessou.tsx" /* webpackChunkName: "component---src-pages-o-que-te-interessou-tsx" */),
  "component---src-pages-offline-tsx": () => import("./../../../src/pages/offline.tsx" /* webpackChunkName: "component---src-pages-offline-tsx" */),
  "component---src-pages-repetir-pedido-tsx": () => import("./../../../src/pages/repetir-pedido.tsx" /* webpackChunkName: "component---src-pages-repetir-pedido-tsx" */),
  "component---src-pages-restricao-alimentar-tsx": () => import("./../../../src/pages/restricao-alimentar.tsx" /* webpackChunkName: "component---src-pages-restricao-alimentar-tsx" */),
  "component---src-pages-s-tsx": () => import("./../../../src/pages/s.tsx" /* webpackChunkName: "component---src-pages-s-tsx" */),
  "component---src-pages-slug-tsx": () => import("./../../../src/pages/[...slug].tsx" /* webpackChunkName: "component---src-pages-slug-tsx" */),
  "component---src-pages-tabloides-tsx": () => import("./../../../src/pages/tabloides.tsx" /* webpackChunkName: "component---src-pages-tabloides-tsx" */),
  "component---src-pages-vendas-whatsapp-tsx": () => import("./../../../src/pages/vendas-whatsapp.tsx" /* webpackChunkName: "component---src-pages-vendas-whatsapp-tsx" */)
}

