// Store ID
const account = 'domolivio'
const production = 'emporiodomolivio'

// SalesChannels
const salesChannels = {
  default: 1,
  club: 2,
}

// Salesforce chat
const salesForceChat = {
  id: '00D5f000005VE3f',
  name: 'Chat_aprimorado_E_commerce_Dom_Olivio',
  url_message:
    'https://smrholding.my.site.com/ESWChataprimoradoEcomm1729610311140',
  url_script:
    'https://smrholding.my.site.com/ESWChataprimoradoEcomm1729610311140/assets/js/bootstrap.min.js',
}

const salesForceActivated = true

// GTM-IDs
const gtmIdTest = 'GTM-MVWKZ3W'
const gtmIdProduction = 'GTM-NR8J84T'

module.exports = {
  account,
  production,

  // Ecommerce Platform
  platform: 'vtex',

  experimental: {
    nodeVersion: 14,
  },

  // Platform specific configs for API
  api: {
    storeId: account,
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  // Default channel
  session: {
    currency: {
      code: 'BRL',
      symbol: 'R$',
    },
    locale: 'pt-br',
    channel: `{"salesChannel":"${salesChannels.default}","regionId":""}`,
    country: 'BRA',
    postalCode: null,
    person: null,
  },

  /*
    https://www.emporiodomolivio.com.br
    https://secure.emporiodomolivio.com.br
  */
  // Production URLs
  storeUrl: `https://www.emporiodomolivio.com.br`,
  secureSubdomain: `https://secure.emporiodomolivio.com.br`,
  checkoutUrl: `https://secure.emporiodomolivio.com.br/api/io/cart`,
  loginUrl: `https://secure.emporiodomolivio.com.br/api/io/login`,
  accountUrl: `https://secure.emporiodomolivio.com.br/api/io/account`,
  salesForceChat,
  salesForceActivated,

  // Jitterbit flows urls
  jitterbitUrls: {
    createUser:
      'https://smrholding.wevo.io/iomanager/api/flows/execute/route/prd/user/v1/create',
  },

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      home: '/',
      pdp: '/presunto-magro-peca-perdigao-kg-74/p',
      collection: '/alimentos/frios-e-embutidos/frios-e-fatiados/presuntos',
    },
  },

  // SalesChannels
  salesChannels,

  // E2E CI
  cypress: {
    pages: {
      home: '/',
      pdp: '/refrigerante-sem-acucar-coca-cola-pet-2l-2627/p',
      collection: '/bebidas/bebidas/refrigerantes/cola',
      collection_filtered:
        '/bebidas/bebidas/refrigerantes/cola/?brand=coca-cola&category-1=bebidas&category-2=bebidas&category-3=refrigerantes&category-4=cola&facets=brand%2Ccategory-1%2Ccategory-2%2Ccategory-3%2Ccategory-4&sort=score_desc&page=0',
      search: '/s/?q=coca',
    },
  },

  hotjarId: '3312653',
  crazyEgg: true,

  // Meta Tags
  metaTags: {
    'google-site-verification': 'Thp0W69anGIE7lqNrFGbUXUs9eD7SIrRmbZY9lEhMfc',
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId:
      process.env.FASTSTORE_ENV === 'production' ? gtmIdProduction : gtmIdTest,
  },
}
